$base_background: #0e1317;
$base_text_color: rgba(255,255,255,0.6);
$base_white_color: #fff;
$base_link_color: #b99272;
$base_hover_color: #b99272;
$base_font_size: 16px;
$extra_font_size: 15px;
$base_font_family: 'Roboto';
$base_font2_family: 'Oswald';
$base_font3_family: 'Merienda';
$base_width: 1170px;
$base_form_placeholder: rgba(0, 0, 0, 0.5);
