/*
Template Name: Kaffen
Author: bslthemes
Author URI: https://themeforest.net/user/bslthemes
Version: 1.0.0
*/

/* TABLE OF CONTENTS
	- Base
	- Header
	- Footer
	- Sections
*/

@import "helpers/variables";
@import "helpers/mixins";
@import "base/base";
@import "layout/header";
@import "layout/footer";
@import "pages/layout";
